import { useContext, createContext, useCallback, useState, useMemo } from 'react'
import { GenerateModal } from '../components/character/GenerateModal'

export const GenerateContext = createContext({ handleGenerate: null, fieldName: null, modal: null })

export function useGenerateContext() {
    const context = useContext(GenerateContext);
    if (!context) throw new Error('Use generate context within provider!');
    return context;
}

export function useGenerate({ onGenerateFetch, onApply }) {
    const [isLoading, setIsLoading] = useState(false)
    const [prompt, setPrompt] = useState('')
    const [fieldName, setFieldName] = useState(null)
    const [fieldValue, setFieldValue] = useState(null)
    const [fieldMaxLength, setFieldMaxLength] = useState(null)

    const handleGenerate = useCallback((name, value, maxLength) => {
        setFieldName(name)
        setFieldValue(value)
        setFieldMaxLength(maxLength)
    }, [])

    const modal = useMemo(() => (
        fieldName && <GenerateModal onClose={() => setFieldName(null)} onGenerateFetch={onGenerateFetch} onApply={onApply} />
    ), [fieldName, onGenerateFetch, onApply])

    return useMemo(() => ({
        modal,
        onApply,
        prompt,
        setPrompt,
        fieldName,
        fieldValue,
        fieldMaxLength,
        setFieldValue,
        handleGenerate,
        isLoading,
        setIsLoading
    }), [handleGenerate, fieldName, fieldValue, fieldMaxLength, setFieldValue, modal, onApply, prompt, setPrompt, isLoading, setIsLoading])
}
