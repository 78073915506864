import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoPencil, IoDownloadOutline, IoClose } from "react-icons/io5";

import 'react-image-crop/dist/ReactCrop.css'

import styles from './Create.module.css';

import { CropAvatar } from '../../components/CropAvatar';
import { Loader } from '../../components/Loader';
import { useApi, ApiError } from '../../hooks/useAPI';
import { Form } from '../../components/character';
import { useGenerate, GenerateContext } from '../../hooks/useGenerate';

let tg = window.Telegram.WebApp;

export function Create() {
  const navigate = useNavigate();

  const bioRef = useRef(null);
  const greetingRef = useRef(null);
  const descriptionRef = useRef(null);
  const systemPromptRef = useRef(null);
  const scenarioRef = useRef(null);
  const avatarFileRef = useRef(null);
  const scrollRef = useRef(null);

  const [character, setCharacter] = useState({
    gender: null,
    name: '',
    first_message: '',
    bio: '',
    description: '',
    avatar_url: '',
    system_prompt: '',
    scenario: '',
    tags: [],
    examples: [],
  });

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [success] = useState(null);
  const [user, setUser] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [avatarFile, setAvatarFile] = useState(null);
  const [moderationError, setModerationError] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [importOpen, setImportOpen] = useState(false);
  const [importFromUrl, setImportFromUrl] = useState(false);
  const [importUrl, setImportUrl] = useState('');
  const [counter, setCounter] = useState(0);
  const [importCharacterLoading, setImportCharacterLoading] = useState(false);
  const [importMessage, setImportMessage] = useState('pending');
  const [fullscreen, setFullscreen] = useState(false);

  const apiOptions = useMemo(() => ({
    baseUrl: process.env.REACT_APP_API_URL,
    requestOptions: {
      headers: {
        'x-init-data': tg.initData,
      }
    }
  }), [])

  const { character: { useCreate: createCharacter, useImport: importCharacter, useGenerate: useGenerateRequest } } = useApi(apiOptions)
  const { fetch: createCharacterFetch, data: createCharacterData, loading: createCharacterLoading } = createCharacter()
  const { fetch: importCharacterFetch } = importCharacter()
  const { fetch: generateFetch, } = useGenerateRequest()

  const onApply = useCallback((field, value) => {
    setCharacter({
      ...character,
      [field]: value,
    })
  }, [character])

  const onGenerateFetch = useCallback(async (field, value, prompt) => {
    try {
      const data = await generateFetch({
        body: JSON.stringify({
            id: null,
            prompt,
            field,
            value,
            characterData: character
        })
      })

      return data.result
    } catch (error) {
      setErrors((state) => [...state, error.message])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [character])

  const generate = useGenerate({ onApply, onGenerateFetch })
  const { modal } = generate

  useEffect(() => {
    const lastIndex = errors.length - 1
    const timeout = setTimeout(() => {
      setErrors((state) => {
        return state.splice(0, lastIndex)
      })
    }, 3000)

    return () => clearTimeout(timeout)
  }, [errors])

  useEffect(() => {
    setUser(tg.initDataUnsafe?.user);
    tg.expand()
    tg.BackButton.onClick(() => navigate(`/`))
    tg.disableVerticalSwipes()

    if (!tg.isFullscreen) {
      if ('requestFullscreen' in tg && ['android', 'ios'].includes(tg.platform)) {
        try {
          tg.requestFullscreen()
          setFullscreen(true)
        } catch (e) { }
      }
    } else {
      setFullscreen(true)
    }

    return () => {
      tg.viewportChanged = null
      tg.BackButton.offClick()
    }
  }, [navigate]);

  useEffect(() => { if (user) setLoading(false) }, [user])

  useEffect(() => {
    [bioRef, greetingRef, descriptionRef, scenarioRef, systemPromptRef].forEach((ref) => {
      if (ref.current) {
        ref.current.style.height = "1px";
        ref.current.style.height = (25 + ref.current.scrollHeight) + "px";
      }
    })
  }, [character])

  useEffect(() => {
    if (createCharacterData && createCharacterData?.response) {
      setModerationError(createCharacterData?.response.explanation)
    }
  }, [createCharacterData])

  const onChange = useCallback((key) => (e) => {
    setCharacter({ ...character, [key]: e.target.value })
  }, [character])

  const onAvatarEditClick = useCallback(() => {
    if (!character.avatar_url) {
      avatarFileRef.current.click()
      return
    }

    try {
      const image = new Image();

      image.addEventListener("load", () => {
        const canvas = document.createElement('canvas');

        canvas.width = image.width;
        canvas.height = image.height;

        const ctx = canvas.getContext('2d');

        ctx.drawImage(image, 0, 0);

        canvas.toBlob((blob) => {
          const reader = new FileReader();

          reader.onload = () => {
            const buffer = reader.result;
            setAvatar(URL.createObjectURL(new Blob([buffer], { type: 'image/png' })));
          };
          reader.readAsArrayBuffer(blob);
        });
      }, false);

      image.crossOrigin = 'anonymous';
      image.src = character.avatar_url;

    } catch (e) {
      setErrors((state) => [...state, 'Error loading avatar'])
    }
  }, [character])

  const onAvatarChange = useCallback((e) => {
    const file = e.target.files[0]

    let numberOfBytes = 0;
    for (const file of e.target.files) {
      numberOfBytes += file.size;
    }

    if (numberOfBytes > 1024 * 1024 * 2) {
      setErrors((state) => [...state, 'File size must be less than 2MB'])
      return
    }

    file.arrayBuffer().then((buffer) => {
      setAvatar(URL.createObjectURL(new Blob([buffer], { type: file.type })))
    })
  }, [])

  const onAvatarSaveClick = useCallback((file, blob) => {
    const body = new FormData()

    body.append('file', file)

    const img = URL.createObjectURL(blob)

    setCharacter((prevCharacter) => {
      return {
        ...prevCharacter,
        avatar_url: img,
      }
    })

    setAvatar(null)
    setAvatarFile(file)

    avatarFileRef.current.value = ''
  }, [])

  const onAvatarUpload = useCallback(() => {
    avatarFileRef.current.click()
  }, [avatarFileRef])

  const onAvatarCancelClick = useCallback(() => {
    avatarFileRef.current.value = ''
    setAvatar(null)
  }, [])

  const onSave = useCallback(() => {
    const body = new FormData()

    body.append('name', character.name)
    body.append('gender', character.gender)
    body.append('bio', character.bio)
    body.append('first_message', character.first_message)
    body.append('tags', character.tags.map((tag) => tag.name).join(','))
    body.append('description', character.description)
    body.append('scenario', character.scenario)
    body.append('system_prompt', character.system_prompt)

    body.append('file', avatarFile)

    createCharacterFetch({ body }).then((res) => {
      navigate(`/character/tg/connect/${res.character.id}?redirect-from=create`)
    }).catch(async (e) => {
      let newErrors = []

      if (e instanceof ApiError) {
        if ('explanation' in e.response) {
          setModerationError(e.response.explanation)
        } else if ('errors' in e.response) {
          setFormErrors(e.response.errors)
        } else {
          setErrors((state) => [...state, e.response.error])
        }
      } else {
        setErrors((state) => [...state, e.message])
        setErrors(newErrors)
      }
    })

    avatarFileRef.current.value = ''
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarFile, character, navigate])

  const onImportClick = useCallback(() => {
    setImportOpen(!importOpen)
  }, [importOpen])

  const onImportFromUrlClick = useCallback(() => {
    setImportOpen(false)
    setImportFromUrl(true)
  }, [])

  const onChangeImportUrl = useCallback((e) => {
    setImportUrl(e.target.value)
  }, [])

  const onImportFromUrl = useCallback(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1)
    }, 1000)

    setImportCharacterLoading(true)

    const body = { url: importUrl }

    if (character.id) {
      body.id = character.id
    }

    importCharacterFetch({ body: JSON.stringify(body) })
      .then((data) => {
        setCharacter({
          ...character,
          id: data.character.id,
        })

        const es = new EventSource(
          `${process.env.REACT_APP_API_URL}/character/import/${data.task.id}`,
        )

        es.onmessage = (event) => {
          const data = JSON.parse(event.data)

          try {
            const { character } = JSON.parse(data.message)
            character.tags = character.tags.map((tag) => ({ id: tag, name: tag.toLowerCase() }))
            character.gender = character.gender === 'male' ? 'MALE' : character.gender === 'female' ? 'FEMALE' : 'OTHER'

            setCharacter(character)
          } catch (e) {
            setImportMessage(data.message)
          }

          if (data.status === 'FINISHED' || data.status === 'ERROR') {
            setCounter(0)
            setImportOpen(false)
            setImportFromUrl(false)
            setImportUrl('')
            setImportCharacterLoading(false)
            setImportMessage('')
            clearInterval(interval)
          }

          if (data.status === 'ERROR') {
            setErrors([data.error])
            es.close()
            return
          }

          if (data.status === 'FINISHED') {
            es.close()
          }
        }

        es.onerror = (event) => {
          if (event.target.readyState === EventSource.CLOSED) {
            setErrors(['Connection was closed'])
          } else if (event.target.readyState === EventSource.CONNECTING) {
            setErrors(['Connection is being re-established'])
          } else {
            setErrors(['An unknown error occurred'])
          }

          setImportCharacterLoading(false)
          clearInterval(interval)
        }
      }).catch((e) => {
        setErrors((state) => [...state, e.response.error])
        setCounter(0)
        setImportOpen(false)
        setImportFromUrl(false)
        setImportUrl('')
        setImportCharacterLoading(false)
        setImportMessage('')
        clearInterval(interval)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importUrl, character])

  if (loading) {
    return <Loader />;
  }

  return (
    <GenerateContext.Provider value={generate}>
      <div className={styles.create + ' ' + styles[tg.platform] + ' ' + (fullscreen ? styles.fullscreen : '') + ' ' + (createCharacterLoading || importCharacterLoading || modal ? styles.blur : '')} ref={scrollRef}>

        {success && (
          <div className={styles.success}>
            <div>{success}</div>
          </div>
        )}

        {errors.length > 0 && (
          <div className={styles.error}>
            {errors.map((error, index) => (
              <div key={index}>{error}</div>
            ))}
          </div>
        )}

        {
          moderationError && (
            <div className={styles.error}>
              <div>
                <p>Character is not created due to the following reasons:</p>
                <p>{moderationError.explanation}</p>
                <p>Forbidden categories: {moderationError.categories.join(', ')}</p>
                <p>Forbidden words: {moderationError.forbidden_words.join(', ')}</p>
                <button className={styles.button} onClick={() => setModerationError(null)}>Close</button>
              </div>
            </div>
          )
        }

        {importFromUrl && (
          <div className={styles.overlay + ' ' + styles.importOverlay} onClick={() => setImportFromUrl(false)}>
            <div className={styles.close} onClick={() => setImportFromUrl(false)}><IoClose size={40} /></div>
            <div className={styles.field}>
              <span className={styles.text}>Enter the URL of the web page with the character you want to import and click "Import" button</span>
              <span className={styles.text}>You can use any website, but it should contain the character description</span>
              <p className={styles.text}>AI will extract the information about the character and generate a new one</p>
              <p>You will be charged 300 BStars ⭐ for the import</p>
            </div>
            <div className={styles.field + ' ' + styles.importUrl}>
              <input type="text" placeholder="URL" value={importUrl} onChange={onChangeImportUrl} onClick={(e) => e.stopPropagation()} />
              <button className={styles.button} onClick={onImportFromUrl}>Import (300 BStars ⭐)</button>
            </div>
          </div>
        )}

        {
          (createCharacterLoading || importCharacterLoading) && (
            <div className={styles.spinnerOverlay}>
              {importCharacterLoading && <span>{importMessage}</span>}
              <span>Creating character...</span>
              {importCharacterLoading && <span>{counter}</span>}
              <div className={styles.spinner} />
            </div>
          )
        }

        { modal }

        <div className={styles.container + ' ' + (importFromUrl || createCharacterLoading || importCharacterLoading || modal ? styles.blur : '')}>

          <div className={styles.importContainer}>
            <button className={styles.button + ' ' + styles.import} onClick={onImportClick}>
              <IoDownloadOutline size={20} />
              <span>Import</span>
            </button>
            <div className={styles.importOptions + ' ' + (importOpen ? styles.importOptionsOpen : '')}>
              <button className={styles.button + ' ' + styles.importFromUrl} onClick={onImportFromUrlClick}>
                <span>URL</span>
              </button>
              {/* <button className={styles.button + ' ' + styles.importFromJson}>
              <span>JSON</span>
            </button> */}
            </div>
          </div>

          <div className={styles.field}>
            <span className={styles.name}>{character?.name || 'New character'}</span>
          </div>

          {avatar && (
            <div className={styles.field}>
              <div className={styles.crop}>
                <CropAvatar
                  src={avatar}
                  // loading={avatarUploadLoading}
                  onAvatarSaveClick={onAvatarSaveClick}
                  onAvatarCancelClick={onAvatarCancelClick}
                  onAvatarUpload={onAvatarUpload}
                />
              </div>
            </div>
          )}

          <div className={styles.avatarContainer} style={avatar ? { display: 'none' } : {}}>
            <div className={styles.avatar} style={character.avatar_url ? { backgroundImage: `url(${character.avatar_url})` } : { 'backgroundColor': '#18373a' }} onClick={onAvatarEditClick}> {character.avatar_url ? '' : 'no avatar'}
              <div className={styles.avatarEdit}>
                <IoPencil size={20} />
              </div>
              <input
                ref={avatarFileRef}
                type="file"
                accept="image/*"
                onChange={onAvatarChange}
                style={{ display: 'none' }} />
            </div>
          </div>

          <Form character={character} formErrors={formErrors} setCharacter={setCharacter} onChange={onChange} generate={generate} />
        </div>

        <footer className={styles.footer + ' ' + (importFromUrl || createCharacterLoading || importCharacterLoading || modal ? styles.blur : '')}>
          <button disabled={createCharacterLoading || importCharacterLoading} onClick={onSave} className={styles.button}>Create</button>
        </footer>
      </div>
    </GenerateContext.Provider>
  )
}