import styles from './field.module.css';

export function Input({ value, onChange, maxLength, placeholder, text, error }) {
    return (
        <>
            <div className={styles.field + ' ' + (error ? styles.error : '')}>
              <input type="text" placeholder={placeholder} value={value} minLength={3} maxLength={maxLength} onChange={onChange} />
              <span className={styles.counter}>{value.length || 0}/{maxLength}</span>
            </div>

            <span className={styles.text}>{text}</span>
            {error && <span className={styles.errorMessage}>{error}</span>}
        </>
    )
}