import React, { useCallback, useEffect, useState, useMemo, useRef, createRef } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { IoPencil, IoTrash, IoAddCircle, IoClose } from "react-icons/io5";

import 'react-image-crop/dist/ReactCrop.css'

import styles from './Edit.module.css';
import ModerationGuide from './ModerationGuide';

import { Form } from '../../components/character/Form';

import { CropAvatar } from '../../components/CropAvatar';
import { Loader } from '../../components/Loader';
import { useApi, ApiError } from '../../hooks/useAPI';
import { useGenerate, GenerateContext } from '../../hooks/useGenerate';

let tg = window.Telegram.WebApp;

export function Edit() {
  const navigate = useNavigate();

  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultTab = searchParams.get('tab');

  const bioRef = useRef(null);
  const greetingRef = useRef(null);
  const descriptionRef = useRef(null);
  const systemPromptRef = useRef(null);
  const scenarioRef = useRef(null);
  const avatarFileRef = useRef(null);
  const scrollRef = useRef(null);
  const memoriesRef = useRef([]);
  const newMemoriesRef = useRef([]);

  const [character, setCharacter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(null);
  const [user, setUser] = useState(null);
  const [newAvatar, setNewAvatar] = useState(null);
  const [moderationError, setModerationError] = useState(null);
  const [moderationWarnings, setModerationWarnings] = useState(null);
  const [moderationGuideOpen, setModerationGuideOpen] = useState(false);
  const [tab, setTab] = useState(defaultTab || 'profile');
  const [memories, setMemories] = useState({});
  const [newMemories, setNewMemories] = useState([]);
  const [deletedMemories, setDeletedMemories] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [importOpen, setImportOpen] = useState(false);
  const [importFromUrl, setImportFromUrl] = useState(false);
  const [importUrl, setImportUrl] = useState('');
  const [importMessage, setImportMessage] = useState('');
  const [importCharacterLoading, setImportCharacterLoading] = useState(false);
  const [counter, setCounter] = useState(0);

  const [fullscreen, setFullscreen] = useState(false);

  const [saving, setSaving] = useState(false);

  const userMessageRef = useRef(null);
  const characterMessageRef = useRef(null);
  const [userMessage, setUserMessage] = useState('');
  const [characterMessage, setCharacterMessage] = useState('');

  const [newMemoryText, setNewMemoryText] = useState('');
  const [updatedMemories, setUpdatedMemories] = useState({});

  const [keyboardOpen, setKeyboardOpen] = useState(false);

  const apiOptions = useMemo(() => ({
    baseUrl: process.env.REACT_APP_API_URL,
    requestOptions: {
      headers: {
        'x-init-data': tg.initData,
      }
    }
  }), [])

  const { character: { useGet: getCharacter, useAvatarUpload: avatarUpload, useUpdateProfile: updateProfile, useMemories: getCharacterMemories, useUpdateMemories: updateMemories, useImport: importCharacter, useGenerate: useGenerateRequest } } = useApi(apiOptions)

  const { fetch: getCharacterFetch, data: getCharacterData, error: getCharacterError } = getCharacter(id)
  const { fetch: getCharacterMemoriesFetch, data: getCharacterMemoriesData } = getCharacterMemories(id)
  const { fetch: avatarUploadFetch, loading: avatarUploadLoading } = avatarUpload(id)
  const { fetch: updateProfileFetch, data: updateProfileData, loading: updateProfileLoading } = updateProfile(id)
  const { fetch: updateMemoriesFetch, data: updateMemoriesData, loading: updateMemoriesLoading } = updateMemories(id)
  const { fetch: importCharacterFetch } = importCharacter(id)
  const { fetch: generateFetch } = useGenerateRequest()

  const onApply = useCallback((field, value) => {
    return setCharacter({
      ...character,
      [field]: value,
    })
  }, [character])

  const onGenerateFetch = useCallback(async (field, value, prompt) => {
    try {
      const data = await generateFetch({
        body: JSON.stringify({
            id,
            prompt,
            field,
            value
        })
      })

      return data.result
    } catch (error) {
      setErrors((state) => [...state, error.message])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const generate = useGenerate({ onApply, onGenerateFetch })
  const { modal } = generate

  useEffect(() => {
    setUser(tg.initDataUnsafe?.user);
    tg.expand()
    tg.BackButton.show()
    tg.BackButton.onClick(() => navigate(`/character/${id}`))
    tg.disableVerticalSwipes()

    if (!tg.isFullscreen) {
      if ('requestFullscreen' in tg && ['android', 'ios'].includes(tg.platform)) {
        try {
          tg.requestFullscreen()
          setFullscreen(true)
        } catch (e) {}
      }
    } else {
      setFullscreen(true)
    }

    return () => {
      tg.viewportChanged = null
      tg.BackButton.offClick()
    }
  }, [id, navigate]);

  useEffect(() => {
    if (tab === 'profile') {
      [bioRef, greetingRef, descriptionRef, scenarioRef, systemPromptRef].forEach((ref) => {
        if (ref.current) {
          ref.current.style.height = "1px";
          ref.current.style.height = (25 + ref.current.scrollHeight) + "px";
        }
      })
    } else if (tab === 'dialogues') {
      [userMessageRef].forEach((ref) => {
        if (ref.current) {
          ref.current.style.height = "1px";
          ref.current.style.height = (25 + ref.current.scrollHeight) + "px";
        }
      })
    } else if (tab === 'memory') {
      if (memoriesRef.current.length) {
        memoriesRef.current.forEach((el) => {
          if (el && el.style) {
            el.style.height = "1px";
            el.style.height = (25 + el.scrollHeight) + "px";
          }
        })
      }

      newMemoriesRef.current.forEach((el) => {
        if (el && el.style) {
          el.style.height = "1px";
          el.style.height = (25 + el.scrollHeight) + "px";
        }
      })
    }

  }, [tab, character, memories, newMemories])

  useEffect(() => {
    if (!user) return
    getCharacterFetch()
    getCharacterMemoriesFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    const lastIndex = errors.length - 1
    const timeout = setTimeout(() => {
      setErrors((state) => {
        return state.splice(0, lastIndex)
      })
    }, 3000)

    return () => clearTimeout(timeout)
  }, [errors])

  useEffect(() => {
    setErrors((errors) => {
      if (getCharacterError) {
        return [...errors, getCharacterError]
      }

      return errors
    })
  }, [getCharacterError])

  useEffect(() => {
    if (!updateProfileData) return;

    if (updateProfileData.moderation_error) {
      setModerationError(updateProfileData.moderation_error)
      setCharacter({
        ...character,
        is_public: false,
      })
    }

    if (updateProfileData.moderation_score) {
      setModerationWarnings(
        Object.fromEntries(Object.entries(updateProfileData.moderation_score).filter(([key, value]) => parseFloat(value) > 0.1))
      )
    }
  }, [updateProfileData, character])

  useEffect(() => {
    if (!getCharacterData) return;
    
    setCharacter({
      ...getCharacterData,
    })

    setModerationWarnings(
      Object.fromEntries(Object.entries(getCharacterData.moderation_score).filter(([key, value]) => parseFloat(value) > 0.1))
    )
    setLoading(false)
  }, [getCharacterData])

  useEffect(() => {
    if (!getCharacterMemoriesData) return;
    setMemories(Object.fromEntries(Object.entries(getCharacterMemoriesData.chunks).map(([id, memory]) => [id, memory])));

    Object.values(getCharacterMemoriesData.chunks).forEach(() => {
      memoriesRef.current.push(createRef());
    })
  }, [getCharacterMemoriesData])

  useEffect(() => {
    if (!updateMemoriesData) return;
    setMemories(Object.fromEntries(Object.entries(updateMemoriesData.chunks).map(([id, memory]) => [id, memory])));
    setNewMemories([])
    setUpdatedMemories({})
    setDeletedMemories([])
  }, [updateMemoriesData])

  const onChange = useCallback((key) => (e) => {
    setCharacter({ ...character, [key]: e.target.value })
  }, [character])

  const onAvatarEditClick = useCallback(() => {
    if (!character.avatar_url) {
      avatarFileRef.current.click()
      return
    }

    try {
      const image = new Image();

      image.addEventListener("load", () => {
        const canvas = document.createElement('canvas');

        canvas.width = image.width;
        canvas.height = image.height;

        const ctx = canvas.getContext('2d');

        ctx.drawImage(image, 0, 0);

        canvas.toBlob((blob) => {
          const reader = new FileReader();

          reader.onload = () => {
            const buffer = reader.result;
            setNewAvatar(URL.createObjectURL(new Blob([buffer], { type: 'image/png' })));
          };
          reader.readAsArrayBuffer(blob);
        });
      }, false);

      image.crossOrigin = 'anonymous';
      image.src = character.avatar_url;

    } catch (e) {
      setErrors((state) => [...state, 'Error loading avatar'])
    }
  }, [character])

  const onAvatarChange = useCallback((e) => {
    const file = e.target.files[0]

    let numberOfBytes = 0;
    for (const file of e.target.files) {
      numberOfBytes += file.size;
    }

    const allowedMimeTypes = ['image/jpeg', 'image/png', 'image/webp'];
    if (!allowedMimeTypes.includes(file.type)) {
      setErrors((state) => [...state, 'Invalid file type. Only JPEG, PNG, and WebP are allowed.'])
      return;
    }

    if (numberOfBytes > 1024 * 1024 * 2) {
      setErrors((state) => [...state, 'File size must be less than 2MB'])
      return
    }

    file.arrayBuffer().then((buffer) => {
      setNewAvatar(URL.createObjectURL(new Blob([buffer], { type: file.type })))
    })
  }, [])

  const onAvatarSaveClick = useCallback((file) => {
    const body = new FormData()

    body.append('file', file)

    avatarUploadFetch({ body }).then((res) => {
      setCharacter({
        ...character,
        avatar_url: res.avatar_url,
      })
      setNewAvatar(null)
    }).catch((e) => {
      setErrors((state) => [...state, e.message])
    })

    avatarFileRef.current.value = ''

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [character])

  const onAvatarUpload = useCallback(() => {
    avatarFileRef.current.click()
  }, [avatarFileRef])

  const onAvatarCancelClick = useCallback(() => {
    setNewAvatar(null)
  }, [])

  const onSave = useCallback(() => {
    if (tab === 'memory') {
      setSaving(true)
      updateMemoriesFetch({
        body: JSON.stringify({ create: newMemories, update: updatedMemories, remove: deletedMemories }),
      }).then(() => {
        setSuccess('Memories updated')
        setSaving(false)

        setTimeout(() => {
          setSuccess(null)
        }, 3000)
      }).catch((e) => {
        setErrors((state) => [...state, e.message])
        setSaving(false)
      })

      return;
    }

    setSaving(true)
    updateProfileFetch({
      body: JSON.stringify(character),
    }).then((data) => {
      setSuccess('Character updated')
      setSaving(false)

      setCharacter({
        ...character,
        moderation_score: data.moderation_score,
      })

      setModerationWarnings(
        Object.fromEntries(Object.entries(data.moderation_score).filter(([key, value]) => parseFloat(value) > 0.1))
      )

      setTimeout(() => {
        setSuccess(null)
      }, 3000)
    }).catch((e) => {
      if (e instanceof ApiError) {
        if ('explanation' in e.response) {
          setModerationError(e.response.explanation)
        } else if ('errors' in e.response) {
          setFormErrors(e.response.errors)
        } else {
          setErrors((state) => {
            return [...state, e.response.error]
          })
        }
      } else {
        setErrors((state) => {
          return [...state, e.message]
        })
      }

      setSaving(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [character, newMemories, updatedMemories, deletedMemories, tab])

  const onAddMemory = useCallback(() => {
    if (newMemoryText.length < 250) {
      setErrors((state) => [...state, 'Memory must be at least 250 characters long'])
      return
    }

    setNewMemories([newMemoryText, ...newMemories])
    setNewMemoryText('')
    newMemoriesRef.current.push(createRef());

  }, [newMemories, newMemoryText])

  const onChangeNewMemory = useCallback((index) => (e) => {
    if (index === newMemories.length) {
      setNewMemoryText(e.target.value)
    } else {
      setNewMemories(newMemories.map((memory, i) => i === index ? e.target.value : memory))
    }
  }, [newMemories])

  const onChangeMemory = useCallback((id, index) => (e) => {
    if (e.target.value === memories[id]) {
      if (updatedMemories[id]) {
        const _updatedMemories = { ...updatedMemories }
        delete _updatedMemories[id]
        setUpdatedMemories(_updatedMemories)
      }
      return
    }

    setUpdatedMemories({
      ...updatedMemories,
      [id]: e.target.value,
    })
  }, [memories, updatedMemories])

  const onDeleteMemory = useCallback((id) => {
    const _memories = { ...memories }
    delete _memories[id]

    setMemories(_memories)
    setDeletedMemories((state) => [...state, id])
    if (updatedMemories[id]) {
      const _updatedMemories = { ...updatedMemories }
      delete _updatedMemories[id]
      setUpdatedMemories(_updatedMemories)
    }
  }, [memories, updatedMemories])

  const onDeleteNewMemory = useCallback((index) => {
    setNewMemories(newMemories.filter((_, i) => i !== index))
    newMemoriesRef.current.splice(index, 1)
  }, [newMemories])

  const onAddDialogue = useCallback(() => {
    setCharacter({
      ...character,
      examples: [{ user: userMessage, character: characterMessage }, ...character.examples],
    })

    setUserMessage('')
    setCharacterMessage('')
  }, [character, userMessage, characterMessage])

  const onChangeExample = useCallback((key, index) => (e) => {
    setCharacter({
      ...character,
      examples: character.examples.map((example, i) => i === index ? { ...example, [key]: e.target.value } : example),
    })
  }, [character])

  const onDeleteExample = useCallback((index) => {
    setCharacter({
      ...character,
      examples: character.examples.filter((_, i) => i !== index),
    })
  }, [character])

  const openModerationGuide = useCallback((e) => {
    e.preventDefault()
    setModerationGuideOpen(true)
  }, [])

  const onChangeTab = useCallback((tab) => () => {
    setTab(tab)
    setSearchParams({ tab })
  }, [setTab, setSearchParams])

  const onChangePublish = useCallback((e) => {
    setCharacter({
      ...character,
      is_public: e.target.checked,
    })
  }, [character])

  const onImportClick = useCallback(() => {
    setImportOpen(!importOpen)
  }, [importOpen])

  const onImportFromUrlClick = useCallback(() => {
    setImportOpen(false)
    setImportFromUrl(true)
  }, [])

  const onChangeImportUrl = useCallback((e) => {
    setImportUrl(e.target.value)
  }, [])

  const onImportFromUrl = useCallback((e) => {
    e.stopPropagation()

    const interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1)
    }, 1000)

    setImportCharacterLoading(true)
    setImportFromUrl(false)

    importCharacterFetch({ body: JSON.stringify({ url: importUrl, id: character.id }) })
      .then((data) => {
        setCharacter({
          ...character,
          id: data.character.id,
        })

        const eventSource = new EventSource(
          `${process.env.REACT_APP_API_URL}/character/import/${data.task.id}`,
        )

        eventSource.onmessage = (event) => {
          const data = JSON.parse(event.data)

          setImportMessage(data.message)

          if (data.status === 'FINISHED') {
            getCharacterFetch()
          }

          if (data.status === 'ERROR') {
            setErrors((state) => [...state, data.error])
          }

          if (data.status === 'FINISHED' || data.status === 'ERROR') {
            setCounter(0)
            setImportOpen(false)
            setImportFromUrl(false)
            setImportUrl('')
            setImportCharacterLoading(false)
            setImportMessage('')
            clearInterval(interval)
            eventSource.close()
          }
        }

        eventSource.onerror = (event) => {
          // console.error('Import task error', event)
          // if (event.target.readyState === EventSource.CLOSED) {
          //   console.error('Connection was closed')
          // } else if (event.target.readyState === EventSource.CONNECTING) {
          //   console.error('Connection is being re-established')
          // } else {
          //   console.error('An unknown error occurred')
          // }

          setCounter(0)
          setImportCharacterLoading(false)
          clearInterval(interval)
        }

        eventSource.onclose = () => {
          setCounter(0)
          setImportCharacterLoading(false)
          clearInterval(interval)
        }
      }).catch((e) => {
        setErrors((state) => [...state, e.response.error])
        setCounter(0)
        setImportOpen(false)
        setImportFromUrl(false)
        setImportUrl('')
        setImportCharacterLoading(false)
        setImportMessage('')
        clearInterval(interval)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importUrl, character])

  if (loading) {
    return <Loader />;
  }

  return (
    <GenerateContext.Provider value={generate}>
      <div className={styles.edit + ' ' + styles[tg.platform] + ' ' + (fullscreen ? styles.fullscreen : '') + ' ' + (keyboardOpen ? styles.keyboardOpen : '')} ref={scrollRef}>

      {success && (
        <div className={styles.success}>
          <div>{success}</div>
        </div>
      )}

      {errors.length > 0 && (
        <div className={styles.error}>
          {errors.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      )}

      {
        moderationError && (
          <div className={styles.error}>
            <div>
              <p>Character can't be saved due to the following reasons:</p>
              <p>{moderationError.explanation}</p>
              <p>Forbidden categories: {moderationError.categories.join(', ')}</p>
              <p>Forbidden words: {moderationError.forbidden_words.join(', ')}</p>
              <button className={styles.button} onClick={() => setModerationError(null)}>Close</button>
            </div>
          </div>
        )
      }

      {moderationGuideOpen && (
        <ModerationGuide onClose={() => setModerationGuideOpen(false)} />
      )}

      { modal }

      {importFromUrl && (
        <div className={styles.overlay + ' ' + styles.importOverlay} onClick={() => setImportFromUrl(false)}>
          <div className={styles.close} onClick={() => setImportFromUrl(false)}><IoClose size={40} /></div>
          <div className={styles.field}>
            <span className={styles.text}>Enter the URL of the web page with the character you want to import and click "Import" button</span>
            <span className={styles.text}>You can use any website, but it should contain the character description</span>
            <p className={styles.text}>AI will extract the information about the character and generate a new one</p>
          </div>
          <div className={styles.field + ' ' + styles.importUrl}>
            <input type="text" placeholder="URL" value={importUrl} onChange={onChangeImportUrl} onClick={(e) => e.stopPropagation() } />
            <button className={styles.button} onClick={onImportFromUrl}>Import (300 BStars ⭐)</button>
          </div>
        </div>
      )}

      {
        (avatarUploadLoading || updateProfileLoading || updateMemoriesLoading || importCharacterLoading) && (
          <div className={styles.spinnerOverlay}>
            { importCharacterLoading && <span>{importMessage}</span>}
            <span>Updating character...</span>
            { importCharacterLoading && <span>{counter}</span>}
            <div className={styles.spinner} />
          </div>
        )
      }

      <div className={styles.container + ' ' + (importFromUrl || avatarUploadLoading || updateProfileLoading || updateMemoriesLoading || importCharacterLoading || modal ? styles.blur : '')}>

        <div className={styles.tabs}>
          <div data-tab="profile" className={styles.tab + ' ' + (tab === 'profile' ? styles.active : '')} onClick={onChangeTab('profile')}>
            <span>Profile</span>
          </div>
          <div data-tab="dialogues" className={styles.tab + ' ' + (tab === 'dialogues' ? styles.active : '')} onClick={onChangeTab('dialogues')}>
            <span>Dialogues</span>
          </div>
          <div data-tab="memory" className={styles.tab + ' ' + (tab === 'memory' ? styles.active : '')} onClick={onChangeTab('memory')}>
            <span>Memory</span>
          </div>
        </div>

        {tab === 'profile' && (
          <>
            <div className={styles.importContainer}>
              <button className={styles.button + ' ' + styles.import} onClick={onImportClick}>
                <span>Generate ⭐️</span>
              </button>
              <div className={styles.importOptions + ' ' + (importOpen ? styles.importOptionsOpen : '')}>
                <button className={styles.button + ' ' + styles.importFromUrl} onClick={onImportFromUrlClick}>
                  <span>URL</span>
                </button>
              </div>
            </div>

            <div className={styles.field}>
              <span className={styles.name}>{character?.name}</span>
            </div>

            {newAvatar && (
              <div className={styles.field}>
                <div className={styles.crop}>
                  <CropAvatar
                    src={newAvatar}
                    loading={avatarUploadLoading}
                    onAvatarSaveClick={onAvatarSaveClick}
                    onAvatarCancelClick={onAvatarCancelClick}
                    onAvatarUpload={onAvatarUpload}
                  />
                </div>
              </div>
            )}

            <div className={styles.avatarContainer} style={newAvatar ? { display: 'none' } : {}}>
              <div className={styles.avatar} style={character.avatar_url ? { backgroundImage: `url(${character.avatar_url})` } : { 'backgroundColor': '#18373a' }} onClick={onAvatarEditClick}> {character.avatar_url ? '' : 'no avatar'}
                <div className={styles.avatarEdit}>
                  <IoPencil size={20} />
                </div>
                <input
                  ref={avatarFileRef}
                  type="file"
                  accept="image/jpeg, image/png, image/webp"
                  onChange={onAvatarChange}
                  style={{ display: 'none' }} />
              </div>
            </div>

            {
              Object.keys(moderationWarnings).length > 0 && (
                <div className={styles.field + ' ' + styles.moderation_score}>
                  <span> ⚠️ Character has the following moderation warnings: </span>
                  <div>
                    {
                      Object.entries(moderationWarnings).map(([key, value]) => (
                        value && <div key={key}>{key}: <span className={ value <= 0.2 ? styles.alert : value <= 0.5 ? styles.warning : styles.danger } >{value.toFixed(2)}</span></div>
                    ))
                    }
                  </div>
                  <a href="#genrate" onClick={openModerationGuide}>What does it mean?</a>
                </div>
              )
            }

            <div className={styles.field}>
              <div className={styles.visability}>
                  <span>Private</span>
                  <label className={styles.switch}>
                    <input type="checkbox" checked={character.is_public} onChange={onChangePublish} value={character.is_public} />
                    <span className={styles.slider}></span>
                  </label>
                  <span>Public</span>
                </div>
            </div>

            {/* {
              character._count.telegram_bots === 0 && (
                <>
                  <span className={styles.text}>You need to connect at least one public telegram bot to the character first to make it public <a href="#" onClick={() => navigate(`/character/tg/connect/${character.id}`)}>Connect bot</a></span>
                </>
              )
            } */}
            <span className={styles.text}>Public character available to interact with other users</span>

            <Form character={character} formErrors={formErrors} setCharacter={setCharacter} onChange={onChange} generate={generate} />

          </>
        )}

        {
          tab === 'dialogues' && (
            <>
              {
                Object.keys(moderationWarnings).length > 0 && (
                  <div className={styles.field + ' ' + styles.moderation_score}>
                    <span> ⚠️ Character has the following moderation warnings: </span>
                    <div>
                      {
                        Object.entries(moderationWarnings).map(([key, value]) => (
                          value && <div key={key}>{key}: <span className={ value <= 0.2 ? styles.alert : value <= 0.5 ? styles.warning : styles.danger } >{value.toFixed(2)}</span></div>
                      ))
                      }
                    </div>
                    <a href="#generate" onClick={openModerationGuide}>What does it mean?</a>
                  </div>
                )
              }
              <div className={styles.field + ' ' + styles.dialogueUser}>
                <div className={styles.userAvatar}>U</div>
                <textarea placeholder="User question" ref={userMessageRef} onChange={(e) => setUserMessage(e.target.value)} value={userMessage} maxLength={500} />
                <span className={styles.counter}>{userMessage?.length || 0}/500</span>
              </div>
              <div className={styles.field + ' ' + styles.dialogueCharacter}>
                <div className={styles.characterAvatar}>{character?.name[0]}</div>
                <textarea placeholder={`${character?.name} answer`} ref={characterMessageRef} onChange={(e) => setCharacterMessage(e.target.value)} value={characterMessage} maxLength={500} />
                <span className={styles.counter}>{characterMessage?.length || 0}/500</span>
              </div>
              <button className={styles.button + ' ' + styles.addDialogue} onClick={onAddDialogue}>
                <IoAddCircle size={20} />
              </button>

              <div className={styles.field}>
                {
                  character?.examples.map((example, index) => (
                    <div className={styles.dialogue} key={index}>
                      <div className={styles.field + ' ' + styles.dialogueUser}>
                        <div className={styles.userAvatar}>U</div>
                        <textarea placeholder="User question" value={example.user} maxLength={500} onChange={onChangeExample('user', index)} onFocus={() => setKeyboardOpen(true)} onBlur={() => setKeyboardOpen(false)} />
                        <span className={styles.counter}>{example.user?.length || 0}/500</span>
                      </div>
                      <div className={styles.field + ' ' + styles.dialogueCharacter}>
                        <div className={styles.characterAvatar}>{character?.name[0]}</div>
                        <textarea placeholder={`${character?.name} answer`} value={example.character} maxLength={500} onChange={onChangeExample('character', index)} onFocus={() => setKeyboardOpen(true)} onBlur={() => setKeyboardOpen(false)} />
                        <span className={styles.counter}>{example.character?.length || 0}/500</span>
                      </div>
                      <button className={styles.button + ' ' + styles.deleteDialogue} onClick={() => onDeleteExample(index)}><IoTrash size={20} /></button>
                    </div>
                  ))
                }
              </div>
            </>
          )
        }

        {
          tab === 'memory' && (
            <>
              <div className={styles.field}>
                  <span className={styles.text}>In blabber.live, each AI character has a dedicated lore memory, containing structured facts about their world, backstory, and personal traits. This memory serves as a reference point, ensuring the character remains consistent and authentic within its narrative universe.</span>
                 <br />
                 <span className={styles.text}>Expand your character’s world. Add new facts to their lore and shape their unique story and personality.</span>
              </div>
              <div className={styles.field}>
                 <textarea placeholder="New memory" minLength={250} maxLength={2500} onChange={onChangeNewMemory(newMemories.length)} value={newMemoryText} onFocus={() => setKeyboardOpen(true)} onBlur={() => setKeyboardOpen(false)} />
                 <span className={styles.counter}>{newMemoryText?.length || 0}/2500</span>
              </div>
              <span className={styles.text}>Add new memory to character lore. Minimum length is 250 characters.</span>
              <button className={styles.button + ' ' + styles.addMemory} onClick={onAddMemory}><IoAddCircle size={20} /></button>
              <div className={styles.field}>
                {
                  newMemories.map((memory, index) => (
                    <div className={styles.memory} key={index}>
                      <div className={styles.field}>
                        <textarea placeholder={`Memory`} value={memory} ref={el => newMemoriesRef.current[index] = el} maxLength={2500} onChange={onChangeNewMemory(index)} onFocus={() => setKeyboardOpen(true)} onBlur={() => setKeyboardOpen(false)} />
                        <span className={styles.counter}>{memory?.length || 0}/2500</span>
                      </div>
                      <button className={styles.button + ' ' + styles.deleteMemory} onClick={() => onDeleteNewMemory(index)}><IoTrash size={20} /></button>
                    </div>
                  ))
                }
                {
                  Object.entries(memories).map(([id, memory], index) => (
                    <div className={styles.memory} key={id}>
                      <div className={styles.field}>
                        <textarea placeholder={`Memory`} value={updatedMemories[id] || memory} ref={el => memoriesRef.current[index] = el} maxLength={2500} onChange={onChangeMemory(id, index)} onFocus={() => setKeyboardOpen(true)} onBlur={() => setKeyboardOpen(false)} />
                        <span className={styles.counter}>{updatedMemories[id]?.length || memory?.length || 0}/2500</span>
                      </div>
                      <button className={styles.button + ' ' + styles.deleteMemory} onClick={() => onDeleteMemory(id)}><IoTrash size={20} /></button>
                    </div>
                  ))
                }
              </div>
            </>
          )
        }
      </div>

        <footer className={styles.footer + ' ' + (importFromUrl || avatarUploadLoading || updateProfileLoading || updateMemoriesLoading || importCharacterLoading || modal ? styles.blur : '')}>
          <button disabled={saving} onClick={onSave} className={styles.button}>Save</button>
        </footer>
      </div>
    </GenerateContext.Provider>
  )
}