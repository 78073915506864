import { Input } from './Input'

export function Name({ value, onChange, error }) {
    return (
        <Input
            value={value}
            onChange={onChange}
            maxLength={25}
            placeholder="Name"
            text="Name of your character"
            error={error}
        />
    )
}