import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import {
  IoHeart, IoBookmark, IoChatbubble,
  IoHeartOutline, IoBookmarkOutline, IoChatbubbleOutline,
  IoEyeOutline, IoEyeOffOutline,
  IoTrash
} from "react-icons/io5";
import { FaTelegramPlane } from "react-icons/fa";

import styles from './Show.module.css';

import { Loader } from '../../components/Loader';
import { useApi } from '../../hooks/useAPI';

let tg = window.Telegram.WebApp;

export function Show() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultTab = searchParams.get('tab');

  const { id } = useParams();

  const [character, setCharacter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [user, setUser] = useState(null);
  const [fullscreen, setFullscreen] = useState(false);
  const [tab, setTab] = useState(defaultTab || 'profile');
  const [openedBot, setOpenedBot] = useState(null);
  const [botDeleteBotnames, setBotDeleteBotnames] = useState({});
  const [botDeleteTimeouts, setBotDeleteTimeouts] = useState({});

  const [menuOpen, setMenuOpen] = useState(false);

  const apiOptions = useMemo(() => ({
    baseUrl: process.env.REACT_APP_API_URL,
    requestOptions: {
      headers: {
        'x-init-data': tg.initData,
      }
    }
  }), [])

  const { character: { useGet: getCharacter, useFavorite, useLike, useBots, useDisconnectTgBot } } = useApi(apiOptions)
  const { fetch: getCharacterFetch, data: getCharacterData, error: getCharacterError } = getCharacter(id)
  const { fetch: favoriteFetch, loading: favoriteLoading } = useFavorite(id)
  const { fetch: likeFetch, loading: likeLoading } = useLike(id)
  const { fetch: botsFetch, data: botsData, loading: botsLoading } = useBots(id)
  const { fetch: disconnectTgBotFetch, loading: disconnectTgBotLoading } = useDisconnectTgBot()

  useEffect(() => {
    setUser(tg.initDataUnsafe?.user);
    tg.expand()
    tg.disableVerticalSwipes()
    tg.BackButton.onClick(() => navigate(`/`))

    if (!tg.isFullscreen) {
      if ('requestFullscreen' in tg && ['android', 'ios'].includes(tg.platform)) {
        try {
          tg.requestFullscreen()
          setFullscreen(true)
        } catch (e) {}
      }
    } else {
      setFullscreen(true)
    }

    return () => {
      tg.BackButton.offClick()
    }
  }, [navigate])

  useEffect(() => {
    if (!user) return
    getCharacterFetch()
    botsFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (getCharacterError) {
      setErrors([...errors, getCharacterError])
    }

    const timeout = setTimeout(() => {
      setErrors([])
    }, 3000)

    return () => clearTimeout(timeout)
  }, [getCharacterError, errors])

  useEffect(() => {
    if (!getCharacterData) return;
    setCharacter(getCharacterData)
    setLoading(false)
  }, [getCharacterData])

  const onLikeClick = useCallback(async () => {
    if (!user || !character || likeLoading) return;
    setCharacter({ ...character, is_liked: !character.is_liked, _count: { ...character._count, likes: !character.is_liked ? character._count.likes + 1 : character._count.likes - 1 } })
    await likeFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, character, likeLoading])

  const onFavoriteClick = useCallback(async () => {
    if (!user || !character || favoriteLoading) return;
    setCharacter({ ...character, is_favorite: !character.is_favorite, _count: { ...character._count, favorites: !character.is_favorite ? character._count.favorites + 1 : character._count.favorites - 1 } })
    await favoriteFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, character, favoriteLoading])

  const onChatClick = useCallback(() => {
    setMenuOpen(true)
  }, [])

  const onStartChat = useCallback(() => {
    setMenuOpen(true)
  }, [setMenuOpen])

  const onBotClick = useCallback((botname) => () => {
    tg.openTelegramLink(`https://t.me/${botname}`)
    setMenuOpen(false)
  }, [])

  const onOpenBotClick = useCallback((botname) => () => {
    if (botDeleteBotnames[botname]) return;
    if (openedBot === botname) {
      setOpenedBot(null)
    } else {
      setOpenedBot(botname)
    }
  }, [openedBot, botDeleteBotnames])

  const onBotDelete = useCallback((botname) => async () => {
    if (botDeleteBotnames[botname]) return;
    setBotDeleteBotnames({ ...botDeleteBotnames, [botname]: true })

    setBotDeleteTimeouts({ ...botDeleteTimeouts, [botname]: setTimeout(() => {
      disconnectTgBotFetch({ body: JSON.stringify({ id, telegram_botname: botname }) }).then(() => {
        botsFetch()
        getCharacterFetch()
        setBotDeleteBotnames({ ...botDeleteBotnames, [botname]: false })
        setBotDeleteTimeouts({ ...botDeleteTimeouts, [botname]: null })
        setOpenedBot(null)
      }).catch((error) => {
        alert(`Bot ${botname} deletion failed: ${error}`)
      })
    }, 3100) })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, botDeleteBotnames])

  const onCancelBotDelete = useCallback((botname) => () => {
    if (disconnectTgBotLoading) return;
    setBotDeleteBotnames({ ...botDeleteBotnames, [botname]: false })
    clearTimeout(botDeleteTimeouts[botname])
    setBotDeleteTimeouts({ ...botDeleteTimeouts, [botname]: null })
  }, [botDeleteBotnames, botDeleteTimeouts, disconnectTgBotLoading])

  const onChangeTab = useCallback((tab) => () => {
    setTab(tab)
    setSearchParams({ tab })
  }, [setTab, setSearchParams])

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.show + ' ' + styles[tg.platform] + ' ' + (fullscreen ? styles.fullscreen : '') + ' ' + (menuOpen ? styles.menuOpen : '') }>
      {errors.length > 0 && (
        <div className={styles.error}>
          {errors.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      )}

      {
          menuOpen && (
            <div onClick={() => setMenuOpen(false)} className={styles.overlay} />
          )
      }

      <div className={styles.container}>

      <div className={styles.tabs}>
          <div data-tab="profile" className={styles.tab + ' ' + (tab === 'profile' ? styles.active : '')} onClick={onChangeTab('profile')}>
            <span>Profile</span>
          </div>
          <div data-tab="bots" className={styles.tab + ' ' + (tab === 'bots' ? styles.active : '')} onClick={onChangeTab('bots')}>
            <span>Connected bots</span>
          </div>
          
        </div>

        { tab === 'profile' && (
          <>
            {
              character?.is_author && (
                <div className={styles.edit}>
                  <button onClick={() => navigate(`/character/edit/${id}`)} className={styles.button}>Edit</button>
                </div>
              )
            }

            <div className={styles.field}>
              <span className={styles.name}>{character?.name}</span>
            </div>

            <div className={styles.avatarContainer}>
              <div className={styles.author}>Author @{character?.author.nickname}</div>
              <div className={styles.avatar} style={character.avatar_url ? { backgroundImage: `url(${character.avatar_url})` } : { 'backgroundColor': '#000' }}> {character.avatar_url ? '' : 'no avatar'} </div>
              <div className={styles.stats}>
                <div onClick={onLikeClick}>
                  {character?.is_liked ? <IoHeart /> : <IoHeartOutline />}
                  <span>{character?._count.likes}</span>
                </div>
                <div onClick={onFavoriteClick}>
                  {character?.is_favorite ? <IoBookmark /> : <IoBookmarkOutline />}
                  <span>{character?._count.favorites}</span>
                </div>
                <div onClick={onChatClick}>
                  {character?.is_chat ? <IoChatbubble /> : <IoChatbubbleOutline />}
                  <span>{character?._count.chats}</span>
                </div>
              </div>
            </div>

            <div className={styles.tags}>
              {
                character?.tags.map((tag) => (
                  <div key={tag.id} className={styles.tag}>
                    {tag.name}
                  </div>
                ))
              }
            </div>

            <div className={styles.field}>
              <span className={styles.bio}>{character?.bio}</span>
            </div>

            {createPortal(
              <div className={`${styles.menu} ${menuOpen ? styles.menuOpen : ''}`}>
                {character?.telegram_bots.length === 0 ? (
                  <div className={styles.menuTitle}>This character hasn't public bots. Please connect a bot to start chat.</div>
                ) : (
                  character?.telegram_bots.map((bot) => (
                    <div key={bot.id} className={styles.bot}>
                      <button onClick={onBotClick(bot.botname)} className={styles.button}>Open @{bot.botname}</button>
                    </div>
                  ))
                )}
                <button onClick={() => navigate(`/character/tg/connect/${id}`)} className={styles.button}>Connect to a new bot</button>
                <button onClick={() => setMenuOpen(false)} className={`${styles.button} ${styles.close}`}>Close</button>
              </div>
            , document.body)}

            <button onClick={onStartChat} className={styles.button}>Start chat</button>
          </>
        )}

        {
          botsLoading && (
            <div className={styles.spinner} />
          )
        }

        { tab === 'bots' && (
          <div className={styles.bots}>
            {
              botsData?.bots.length === 0 && (
                <div className={styles.menuTitle}><p>This character hasn't connected bots. Please connect a bot to start chat.</p></div>
              )
            }
            <button onClick={() => navigate(`/character/tg/connect/${id}`)} className={styles.button + ' ' + styles.connect}>Connect new bot</button>
            {
              botsData?.bots.map((bot) => (
                <div key={bot.botname} className={styles.bot + ' ' + (openedBot === bot.botname ? styles.botOpened : '') + ' ' + (botDeleteBotnames[bot.botname] ? styles.botDeleting : '')} onClick={onOpenBotClick(bot.botname)}>
                  <div className={styles.botDeletingOverlay} />
                  {!disconnectTgBotLoading && <div className={styles.botDeletingText} onClick={onCancelBotDelete(bot.botname)}>Tap to cancel</div>}
                  <div className={styles.botContent}>
                    <div className={styles.botHeader}>
                      <div>
                        <span className={styles.botname}>@{bot.botname}</span>
                      </div>
                      <div>
                        <span className={styles.botAuthor}>@{bot.user.nickname}</span>
                      </div>
                    </div>
                    <div className={styles.botFooter}>
                      <div className={styles.botVisability}>
                        {bot.is_public ? <IoEyeOutline /> : <IoEyeOffOutline />}
                      </div>
                      <div className={styles.botBubble}>
                        <IoChatbubbleOutline />
                      </div>
                    </div>
                  </div>
                  <div className={styles.botActions}>
                    <button onClick={onBotClick(bot.botname)} className={styles.button}>
                      <FaTelegramPlane size={22} />
                    </button>
                    <button onClick={onBotDelete(bot.botname)} className={styles.button}>
                      <IoTrash size={22} />
                    </button>
                  </div>
                </div>
              ))
            }
          </div>
        )}
      </div>
    </div>
  )
}