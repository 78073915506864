import React from 'react';

import styles from './NotFound.module.css';

import img from './assets/img.png';

export const NotFound = ({ children }) => {
  return (
    <div className={styles.notFound}>
      <img src={img} alt="blabber.live" className={styles.img} />
      {children}
    </div>
  );
};
