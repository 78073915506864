import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { IoHomeOutline, IoChatbubbleOutline, IoAddCircleOutline, IoPersonOutline } from 'react-icons/io5';
import { FaTelegramPlane } from 'react-icons/fa';

import { useApi } from '../../hooks/useAPI';
import { NotFound } from '../../components/NotFound';
import { Loader } from '../../components/Loader';

import styles from './Chats.module.css';


let tg = window.Telegram.WebApp;

export function Chats() {
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [fullscreen, setFullscreen] = useState(false);

    const apiOptions = useMemo(() => ({
        baseUrl: process.env.REACT_APP_API_URL,
        requestOptions: {
            headers: {
                'x-init-data': tg.initData,
            }
        }
    }), [])

    const { user: { useChats } } = useApi(apiOptions)

    const { fetch: fetchChats, data: chatsData } = useChats();

    useEffect(() => {
        setUser(tg.initDataUnsafe?.user);
        tg.expand()
        tg.BackButton.show()
        tg.BackButton.onClick(() => navigate(`/`))
        tg.disableVerticalSwipes()

        if (!tg.isFullscreen) {
            if ('requestFullscreen' in tg && ['android', 'ios'].includes(tg.platform)) {
                try {
                    tg.requestFullscreen()
                    setFullscreen(true)
                } catch (e) { }
            }
        } else {
            setFullscreen(true)
        }

        setUser(tg.initDataUnsafe?.user);

    }, [navigate])

    useEffect(() => {
        if (!user) return;
        fetchChats()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    useEffect(() => {
        if (chatsData) return;
        setLoading(false)
    }, [chatsData])

    const onBotClick = useCallback((botname) => () => {
        tg.openTelegramLink(`https://t.me/${botname}`)
    }, [])

    const onHomeClick = useCallback(() => navigate(`/`), [navigate])
    const onAddCharacterClick = useCallback(() => navigate(`/character/create`), [navigate])
    const onProfileClick = useCallback(() => navigate(`/profile`), [navigate])

    if (loading) {
        return <Loader />;
    }

    return (
        <div className={styles.chats + ' ' + styles[tg.platform] + ' ' + (fullscreen ? styles.fullscreen : '')}>
            <div className={styles.container}>
                <div className={styles.chatsList}>
                    {
                        chatsData?.chats.length === 0 && (
                            <NotFound>
                                <div className={styles.empty}>No chats yet</div>
                            </NotFound>
                        )
                    }
                    {chatsData?.chats.map((chat) => (
                        <div className={styles.chat} key={chat.id}>
                            <div className={styles.avatar} style={chat.character.avatar_url ? { backgroundImage: `url(${chat.character.avatar_url})` } : { 'backgroundColor': '#000' }}> {chat.character.avatar_url ? '' : 'no avatar'} </div>
                            <div className={styles.info}>
                                <div className={styles.chatName}>{chat.character.name}</div>
                                <div className={styles.botName}>@blabber_live_arrasdasdasdaragi_bot</div>
                                <div className={styles.messages}><IoChatbubbleOutline /> {chat._count.messages} Messages</div>
                            </div>
                            <div className={styles.actions}>
                                <button onClick={onBotClick(chat.telegram_bot.botname)} className={styles.button}>
                                    <FaTelegramPlane size={22} />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                <footer className={styles.footer}>
                    <div className={styles.footerTabs}>
                        <button onClick={onHomeClick}><IoHomeOutline /></button>
                        <button><IoChatbubbleOutline /></button>
                        <button onClick={onAddCharacterClick}><IoAddCircleOutline /></button>
                        <button onClick={onProfileClick}><IoPersonOutline /></button>
                    </div>
                </footer>
            </div>
        </div>
    );
}
