import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { IoSearchSharp, IoArrowDown } from "react-icons/io5";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IoHeartOutline, IoBookmarkOutline, IoChatbubbleOutline, IoCloseOutline, IoHomeOutline, IoAddCircleOutline, IoPersonOutline, IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

import styles from './Home.module.css';

import { Loader } from '../../components/Loader';
import { NotFound } from '../../components/NotFound';
import { useApi } from '../../hooks/useAPI';

let tg = window.Telegram.WebApp;

const highlightText = (text, query) => {
  if (!query) return text;
  const parts = text.split(new RegExp(`(${query})`, 'gi'));
  return parts.map((part, index) => 
    part.toLowerCase() === query.toLowerCase() ? <span key={index} className={styles.highlight}>{part}</span> : part
  );
};

export function Home() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tagsParam = searchParams.get('tags')
  const tabParam = searchParams.get('tab')

  const [characters, setCharacters] = useState({});
  const [total, setTotal] = useState(0);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchFocused, setSearchFocused] = useState(false);
  const [tags, setTags] = useState([]);
  const [fullscreen, setFullscreen] = useState(false);
  const [moreTagsOpen, setMoreTagsOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');

  const [requestParams, setRequestParams] = useState({
    page: 1,
    pageSize: (Math.round((Math.min(window.innerWidth, 850) / 188) * (window.innerHeight / 430))),
    tags: tagsParam ? tagsParam.split(',').map((tag) => parseInt(tag)) : [],
    tab: tabParam ? tabParam : null
  })

  const charactersRef = useRef(null);

  const apiOptions = useMemo(() => ({
    baseUrl: process.env.REACT_APP_API_URL,
    requestOptions: {
      headers: {
        'x-init-data': tg.initData,
      }
    }
  }), [])

  const { character: { useList, useSearch } } = useApi(apiOptions)

  const { fetch: getCharactersFetch, loading: getCharactersLoading, data: getCharactersData } = useList()
  const { fetch: getCharactersSearchFetch, loading: getCharactersSearchLoading, data: getCharactersSearchData, reset: getCharactersSearchReset } = useSearch()

  const handleResize = useCallback(() => {
    const newPageSize = Math.round((Math.min(window.innerWidth, 850) / 188) * (window.innerHeight / 430))

    if (newPageSize <= requestParams.pageSize) return

    setRequestParams((prevParams) => ({ ...prevParams, page: 1, pageSize: newPageSize }))
  }, [requestParams.pageSize])

  const handleScroll = useCallback((e) => {
    if (e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) <= 100) return;
    if (getCharactersLoading || Object.values(characters).length >= total) return;

    const nextPage = Math.ceil(Object.values(characters).length / requestParams.pageSize) + 1

    setRequestParams((prevParams) => {
      return { ...prevParams, page: nextPage }
    })
  }, [requestParams.pageSize, characters, total, getCharactersLoading])

  useEffect(() => {
    setSearchParams({ tags: requestParams.tags.join(','), tab: requestParams.tab })
  }, [requestParams.tags, requestParams.tab, setSearchParams])

  useEffect(() => {
    tg.BackButton.hide()
    tg.expand()
    tg.disableVerticalSwipes()
    
    if (!tg.isFullscreen) {
      if ('requestFullscreen' in tg && ['android', 'ios'].includes(tg.platform)) {
        try {
          tg.requestFullscreen()
          setFullscreen(true)
        } catch (e) {}
      }
    } else {
      setFullscreen(true)
    }

    setUser(tg.initDataUnsafe?.user);

    // if (tg.version >= '8.0') {
    //   try {
    //     tg.addToHomeScreen()
    //   } catch (e) {
    //     console.log(e)
    //   }
    // }

  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  useEffect(() => {
    if (!user) return
    getCharactersFetch({ query: requestParams })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestParams, user])

  useEffect(() => {
    if (!getCharactersData || getCharactersLoading) return

    if (requestParams.page > 1) {
      setCharacters((prevCharacters) => {
        const newCharacters = Object.fromEntries(
          getCharactersData.characters
            .filter((character) => !prevCharacters[character.id])
            .map((character) => [character.id, character])
        );
        return { ...prevCharacters, ...newCharacters };
      });
    } else {
      setCharacters((prevCharacters) => {
        const newCharacters = Object.fromEntries(
          getCharactersData.characters
            .filter((character) => !prevCharacters[character.id])
            .map((character) => [character.id, character])
        );

        const prevNewCharacters = Object.fromEntries(
          Object.entries(prevCharacters)
            .filter(([id]) => getCharactersData.characters.some((character) => character.id === parseInt(id)))
        );

        return { ...prevNewCharacters, ...newCharacters };
      });
    }

    setTotal(getCharactersData.total)
    setTags(getCharactersData.tags)
    setLoading(false)
  }, [getCharactersData, setSearchParams, setTotal, setTags, setLoading, getCharactersLoading, requestParams.page])

  const onAddCharacterClick = useCallback(() => {
    tg.BackButton.show()
    navigate('/character/create')
  }, [navigate])

  const onProfileClick = useCallback(() => {
    tg.BackButton.show()
    navigate('/profile')
  }, [navigate])

  const onCharacterClick = useCallback((id) => () => {
    tg.BackButton.show()
    navigate(`/character/${id}`)
  }, [navigate])

  const onChatsClick = useCallback(() => {
    tg.BackButton.show()
    navigate('/chats')
  }, [navigate])

  const onSearchChange = useCallback((e) => {
    getCharactersSearchReset()

    setSearchQuery(e.target.value)

    if (getCharactersSearchLoading || e.target.value.length < 3) return
    getCharactersSearchFetch({ query: { query: e.target.value, pageSize: 6 } })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onTagClick = useCallback((tagId) => () => {
    setRequestParams((prevParams) => {
      let newSearchTags = [...prevParams.tags]

      if (prevParams.tags.includes(tagId)) {
        newSearchTags = newSearchTags.filter((id) => id !== tagId)
      } else {
        newSearchTags.push(tagId)
      }

      return { ...prevParams, tags: newSearchTags, page: 1 }
    })
  }, [])

  const onTabClick = useCallback((_tab) => () => {
    setRequestParams((prevParams) => {
      if (prevParams.tab === _tab) return { ...prevParams, tab: null, page: 1 }
      return { ...prevParams, tab: _tab, page: 1 }
    })
  }, [])

  const onMoreTagsClick = useCallback(() => {
    setMoreTagsOpen((prev) => !prev)
  }, [])

  const onSearchClose = useCallback(() => {
    setSearchQuery('')
    setSearchFocused(false)
    getCharactersSearchReset()
  }, [getCharactersSearchReset])

  if (loading) {
    return <Loader />;
  }

  return (
      <div className={styles.home + ' ' + (searchFocused ? styles.searchFocused : '' ) + ' ' +styles[tg.platform] + ' ' + (fullscreen ? styles.fullscreen : '')} onScroll={handleScroll}>
        <div className={styles.container}>

          {/* {
            getCharactersLoading && (
              <div className={styles.spinner} />
            )
          } */}

          <div className={styles.overlay + ' ' + (searchFocused ? styles.searchFocused : '')}>
            <div className={styles.searchContainer}>
              <div className={styles.searchPanel}>
                <div className={styles.searchInputWrapper}>
                  <IoSearchSharp className={styles.searchIcon} />
                  <input type="text" placeholder="Search" onFocus={() => setSearchFocused(true)} onChange={onSearchChange} value={searchQuery} />
                  <IoCloseOutline className={styles.searchCloseIcon} onClick={onSearchClose} />
                </div>
              </div>
              <div className={styles.searchResults}>
                <div className={styles.searchCharactersResults}>
                  <div className={styles.searchCharactersResultsTitle}>Characters</div>
                  {
                    getCharactersSearchData?.characters.map((character) => (
                      <div className={styles.searchCharacter} key={character.id} onClick={onCharacterClick(character.id)}>
                        <div className={styles.avatar} style={ character.avatar_url ? { backgroundImage: `url(${character.avatar_url})` } : { 'backgroundColor': '#000' }}> { character.avatar_url ? '' : 'no avatar' } </div>
                        <div className={styles.name}>{highlightText(character.name, searchQuery)}</div>
                      </div>
                    ))
                  }
                </div>
                <div className={styles.searchTagsResults}>
                  <div className={styles.searchTagsResultsTitle}>Tags</div>
                  {
                    getCharactersSearchData?.tags.map((tag) => (
                      <div key={tag.id} data-tag-id={tag.id} className={styles.searchTag + ' ' + (requestParams.tags.includes(tag.id) ? styles.searchTagActive : '')} onClick={onTagClick(tag.id)}>
                        {highlightText(tag.name, searchQuery)}
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
          <header>
            <div className={styles.tags}>
              {
                tags.slice(0, moreTagsOpen ? tags.length : 5).map((tag) => (
                  <span key={tag.id} data-tag-id={tag.id} className={styles.tag + ' ' + (requestParams.tags.includes(tag.id) ? styles.tagActive : '')} onClick={onTagClick(tag.id)}>
                    {tag.name}
                  </span>
                ))
              }
              {
                tags.length > 5 && (
                  <span className={styles.more + ' ' + styles.tag + ' ' + (moreTagsOpen ? styles.moreOpen : '')} onClick={onMoreTagsClick}>{moreTagsOpen ? 'less' : 'more'} {<IoArrowDown />}</span>
                )
              }
            </div>
            <div className={styles.tabs}>
              <div data-tab="my" className={styles.tab + ' ' + (requestParams.tab === 'my' ? styles.tabActive : '')} onClick={onTabClick('my')}>
                <IoPersonOutline />
                <span>My</span>
              </div>
              <div data-tab="liked" className={styles.tab + ' ' + (requestParams.tab === 'liked' ? styles.tabActive : '')} onClick={onTabClick('liked')}>
                <IoHeartOutline />
                <span>Likes</span>
              </div>
              <div data-tab="favorite" className={styles.tab + ' ' + (requestParams.tab === 'favorite' ? styles.tabActive : '')} onClick={onTabClick('favorite')}>
                <IoBookmarkOutline />
                <span>Favorites</span>
              </div>
              <div data-tab="chat" className={styles.tab + ' ' + (requestParams.tab === 'chat' ? styles.tabActive : '')} onClick={onTabClick('chat')}>
                <IoChatbubbleOutline />
                <span>Chats</span>
              </div>
            </div>
          </header>
          {
            Object.values(characters).length === 0 && (
              <NotFound>
                <div>No characters found</div>
              </NotFound>
            )
          }
          <div className={styles.characters} ref={charactersRef}>
            {
              Object.values(characters).map((character) => (
                <div key={character.id} className={styles.character} onClick={onCharacterClick(character.id)}>
                  <div className={styles.info}>
                    <div className={styles.visability}>{character.is_public ? <IoEyeOutline /> : <IoEyeOffOutline />}</div>
                    <div className={styles.avatar} style={ character.avatar_url ? { backgroundImage: `url(${character.avatar_url})` } : { 'backgroundColor': '#000' }}> { character.avatar_url ? '' : 'no avatar' } </div>
                    <span className={styles.name}>{character.name}</span>
                    <span className={styles.description}>{character.bio.slice(0, 100)}...</span>
                    <div className={styles.tags}>
                      {
                        character.tags.map((tag) => (
                          <span key={tag.id} className={styles.tag}>{tag.name}</span>
                        ))
                      }
                    </div>
                    <div className={styles.stats}>
                      <div>
                        <IoHeartOutline />
                        <span>{character?._count.likes}</span>
                      </div>
                      <div>
                        <IoBookmarkOutline />
                        <span>{character?._count.favorites}</span>
                      </div>
                      <div>
                        <IoChatbubbleOutline />
                        <span>{character?._count.chats}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
            {
              getCharactersLoading && Array.from({ length: Math.min(total - Object.values(characters).length, requestParams.pageSize) }).map((_, index) => (
                <div key={index} className={styles.emptyCharacter}>
                  <div className={styles.avatar} />
                  <span className={styles.name} />
                  <span className={styles.description} />
                  <div className={styles.tags}>
                    {
                      Array.from({ length: 3 }).map((_, index) => (
                        <span key={index} className={styles.tag} />
                      ))
                    }
                  </div>
                </div>
              ))
            }

            <footer className={styles.footer}>
              <div className={styles.footerTabs}>
                <button><IoHomeOutline /></button>
                <button onClick={onChatsClick}><IoChatbubbleOutline /></button>
                <button onClick={onAddCharacterClick}><IoAddCircleOutline /></button>
                <button onClick={onProfileClick}><IoPersonOutline /></button>
              </div>
            </footer>

          </div>
        </div>
      </div>
    )
  }