import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { IoHomeOutline, IoChatbubbleOutline, IoAddCircleOutline, IoPersonOutline, IoEyeOutline, IoEyeOffOutline, IoTrash } from 'react-icons/io5';
import { FaTelegramPlane } from 'react-icons/fa';

import { useApi } from '../../hooks/useAPI';
import { Loader } from '../../components/Loader';

import styles from './Profile.module.css';


let tg = window.Telegram.WebApp;

export function Profile() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultTab = searchParams.get('tab');

    const [tab, setTab] = useState(defaultTab || 'profile');
    const [fullscreen, setFullscreen] = useState(false);
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [amount, setAmount] = useState(5000);
    const [invoiceError, setInvoiceError] = useState(null);
    const [nicknameIsChanged, setNicknameIsChanged] = useState(false);
    const [nicknameError, setNicknameError] = useState(null);
    const [openedBot, setOpenedBot] = useState(null);
    const [botDeleteBotnames, setBotDeleteBotnames] = useState({});
    const [botDeleteTimeouts, setBotDeleteTimeouts] = useState({});
    const [success, setSuccess] = useState(null);

    const apiOptions = useMemo(() => ({
        baseUrl: process.env.REACT_APP_API_URL,
        requestOptions: {
            headers: {
                'x-init-data': tg.initData,
            }
        }
    }), [])

    const { user: { useMe, useInvoice, useUpdateNickname, useBots }, character: { useDisconnectTgBot } } = useApi(apiOptions)
    const { fetch: fetchInvoice } = useInvoice();
    const { fetch: fetchUpdateNickname, loading: updateNicknameLoading } = useUpdateNickname();
    const { loading, fetch: fetchMe } = useMe();
    const { fetch: botsFetch, data: botsData } = useBots();
    const { fetch: disconnectTgBotFetch, loading: disconnectTgBotLoading } = useDisconnectTgBot()

    useEffect(() => {
        setUser(tg.initDataUnsafe?.user);
        tg.expand()
        tg.BackButton.show()
        tg.BackButton.onClick(() => navigate(`/`))
        tg.disableVerticalSwipes()

        if (!tg.isFullscreen) {
            if ('requestFullscreen' in tg && ['android', 'ios'].includes(tg.platform)) {
                try {
                    tg.requestFullscreen()
                    setFullscreen(true)
                } catch (e) { }
            }
        } else {
            setFullscreen(true)
        }

        setUser(tg.initDataUnsafe?.user);

    }, [navigate])

    useEffect(() => {
        if (!user) return;
        fetchMe().then((data) => setUserData(data))
        botsFetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const onChangeAmount = useCallback((value) => {
        setInvoiceError(null)
        setAmount(value)
    }, [])

    const onAddCharacterClick = useCallback(() => navigate(`/character/create`), [navigate])
    const onChatsClick = useCallback(() => navigate(`/chats`), [navigate])
    const onHomeClick = useCallback(() => navigate(`/`), [navigate])

    const onSendInvoice = useCallback((amount) => {
        if (amount < 100) {
            setAmount(100);
            setInvoiceError(' Minimum purchase amount is 100 BStars ⭐.');
            return;
        }

        if (amount % 100 !== 0) {
            setAmount(Math.ceil(amount / 100) * 100);
            setInvoiceError('Amount must be a multiple of 100.');
            return;
        }

        if (amount > 10000000) {
            setAmount(10000000);
            setInvoiceError('Maximum purchase amount is 10 000 000 BStars ⭐.');
            return;
        }

        fetchInvoice({ body: JSON.stringify({ amount }) }).then((data) => {
            console.log(data);
        }).catch((error) => {
            console.error(error);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChangeNickname = useCallback((e) => {
        setNicknameIsChanged(e.target.value !== userData.nickname);
        setUserData({ ...userData, nickname: e.target.value.toLowerCase().replace(/[^a-z0-9_]/g, '').trim() })
    }, [userData]);

    const onSaveNickname = useCallback(() => {
        setNicknameError(null)
        console.log(userData.nickname)
        fetchUpdateNickname({ body: JSON.stringify({ nickname: userData.nickname }) }).then((data) => {
            setUserData({ ...userData, nickname: data.nickname })
            setSuccess('Nickname changed successfully')
            setTimeout(() => {
                setSuccess(null)
            }, 3000)
        }).catch((error) => {
            setNicknameError(error.message)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData])

    const onChangeTab = useCallback((tab) => () => {
        setTab(tab)
        setSearchParams({ tab })
    }, [setTab, setSearchParams])

    const onBotClick = useCallback((botname) => () => {
        tg.openTelegramLink(`https://t.me/${botname}`)
    }, [])

    const onOpenBotClick = useCallback((botname) => () => {
        if (botDeleteBotnames[botname]) return;
        if (openedBot === botname) {
            setOpenedBot(null)
        } else {
            setOpenedBot(botname)
        }
    }, [openedBot, botDeleteBotnames])

    const onBotDelete = useCallback((character_id, botname) => async () => {
        if (botDeleteBotnames[botname]) return;
        setBotDeleteBotnames({ ...botDeleteBotnames, [botname]: true })

          setBotDeleteTimeouts({ ...botDeleteTimeouts, [botname]: setTimeout(() => {
            disconnectTgBotFetch({ body: JSON.stringify({ id: character_id, telegram_botname: botname }) }).then(() => {
              botsFetch()
              setBotDeleteBotnames({ ...botDeleteBotnames, [botname]: false })
              setBotDeleteTimeouts({ ...botDeleteTimeouts, [botname]: null })
              setOpenedBot(null)
            }).catch((error) => {
              alert(`Bot ${botname} deletion failed: ${error}`)
            })
          }, 3100) })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [botDeleteBotnames])

    const onCancelBotDelete = useCallback((botname) => () => {
        if (disconnectTgBotLoading) return;
        setBotDeleteBotnames({ ...botDeleteBotnames, [botname]: false })
        clearTimeout(botDeleteTimeouts[botname])
        setBotDeleteTimeouts({ ...botDeleteTimeouts, [botname]: null })
    }, [botDeleteBotnames, botDeleteTimeouts, disconnectTgBotLoading])

    if (loading || !userData) {
        return <Loader />;
    }

    return (
        <div className={styles.profile + ' ' + styles[tg.platform] + ' ' + (fullscreen ? styles.fullscreen : '')}>
            <div className={styles.container}>
                <div className={styles.tabs}>
                    <div data-tab="profile" className={styles.tab + ' ' + (tab === 'profile' ? styles.active : '')} onClick={onChangeTab('profile')}>
                        <span>Profile</span>
                    </div>
                    <div data-tab="bots" className={styles.tab + ' ' + (tab === 'bots' ? styles.active : '')} onClick={onChangeTab('bots')}>
                        <span>Bots</span>
                    </div>
                </div>

                {success && (
                    <div className={styles.success}>
                        <div>{success}</div>
                    </div>
                )}

                {tab === 'profile' && (
                    <>
                        <div className={styles.field}>
                            <div className={styles.fieldLabel}>
                                Account name
                            </div>
                            <div className={styles.fieldValue + ' ' + styles.nickname + ' ' + (nicknameError ? styles.fieldError : '')}>
                                <input disabled={updateNicknameLoading} value={userData.nickname} onChange={onChangeNickname} />
                                {nicknameIsChanged && <button disabled={updateNicknameLoading} onClick={onSaveNickname}>Save</button>}
                            </div>
                            {nicknameError && <div className={styles.errorMessage}>{nicknameError}</div>}
                        </div>

                        <div className={styles.field + ' ' + styles.balance}>
                            <div className={styles.fieldLabel}>
                                Your balance
                            </div>
                            <div className={styles.fieldValue}>
                                <span>{userData.stars} ⭐</span>
                            </div>
                        </div>

                        <div className={styles.field + ' ' + styles.buyStars + ' ' + (invoiceError ? styles.fieldError : '')}>
                            <div>
                                <span>Buy BStars ⭐</span>
                                <span>1 Telegram Star = 100 BStars</span>
                            </div>
                            <div className={styles.buyStarsButtons}>
                                <button onClick={() => onChangeAmount(25000)}>25.000</button>
                                <button onClick={() => onChangeAmount(30000)}>30.000</button>
                                <button onClick={() => onChangeAmount(50000)}>50.000</button>
                                <button onClick={() => onChangeAmount(100000)}>100.000</button>
                            </div>
                            <div>
                                <span>BStars</span>
                                <input value={amount} onChange={(e) => onChangeAmount(e.target.value)} /> ⭐
                                <button onClick={() => onSendInvoice(amount)}>Buy</button>
                            </div>
                            {invoiceError && <div className={styles.errorMessage}>{invoiceError}</div>}
                        </div>
                    </>
                )}

                {tab === 'bots' && (
                    <div className={styles.bots}>
                        {
                            botsData?.bots.length === 0 && (
                                <div className={styles.menuTitle}><p>You haven't connected any bots yet.</p></div>
                            )
                        }
                        {
                            botsData?.bots.map((bot) => (
                                <div key={bot.botname} className={styles.bot + ' ' + (openedBot === bot.botname ? styles.botOpened : '') + ' ' + (botDeleteBotnames[bot.botname] ? styles.botDeleting : '')} onClick={onOpenBotClick(bot.botname)}>
                                    <div className={styles.botDeletingOverlay} />
                                    {!disconnectTgBotLoading && <div className={styles.botDeletingText} onClick={onCancelBotDelete(bot.botname)}>Tap to cancel</div>}
                                    <div className={styles.botContent}>
                                        <div className={styles.botHeader}>
                                            <div>
                                                <span className={styles.botname}>@{bot.botname}</span>
                                            </div>
                                            <div>
                                                <span className={styles.botAuthor}>@{bot.user.nickname}</span>
                                            </div>
                                        </div>
                                        <div className={styles.botFooter}>
                                            <div className={styles.botVisability}>
                                                {bot.is_public ? <IoEyeOutline /> : <IoEyeOffOutline />}
                                            </div>
                                            <div className={styles.botBubble}>
                                                <IoChatbubbleOutline />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.botActions}>
                                        <button onClick={onBotClick(bot.botname)} className={styles.button}>
                                            <FaTelegramPlane size={22} />
                                        </button>
                                        <button onClick={onBotDelete(bot.character.id, bot.botname)} className={styles.button}>
                                            <IoTrash size={22} />
                                        </button>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )}
            </div>

            <footer className={styles.footer}>
                <div className={styles.footerTabs}>
                    <button onClick={onHomeClick}><IoHomeOutline /></button>
                    <button onClick={onChatsClick}><IoChatbubbleOutline /></button>
                    <button onClick={onAddCharacterClick}><IoAddCircleOutline /></button>
                    <button ><IoPersonOutline /></button>
                </div>
            </footer>
        </div>
    );
}
