import { useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'

import 'react-image-crop/dist/ReactCrop.css';

import styles from './CropAvatar.module.css';

export function CropAvatar({ src, loading, onAvatarSaveClick, onAvatarCancelClick, onAvatarUpload }) {
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [preview, setPreview] = useState(null)
    const [blob, setBlob] = useState(null)

    const imgRef = useRef(null)

    const onLoad = useCallback(() => {
        if (!imgRef.current) return
        const { naturalWidth: width, naturalHeight: height } = imgRef.current

        const crop = centerCrop(
            makeAspectCrop(
                {
                    unit: 'px',
                    width: Math.min(imgRef.current.width, imgRef.current.height),
                    height: Math.min(imgRef.current.width, imgRef.current.height),
                    x: 0,
                    y: 0,
                },
                1 / 1,
                imgRef.current.width,
                imgRef.current.height,
            ),
            {
                width,
                height,
                x: 0,
                y: 0,
            },
            imgRef.current.width,
            imgRef.current.height,
        )

        crop.x = (imgRef.current.width - crop.width) / 2
        crop.y = (imgRef.current.height - crop.height) / 2

        setCrop(crop)
        setCompletedCrop(crop)
    }, [imgRef])

    useEffect(() => {
        if (!imgRef.current) return
        setTimeout(onLoad, 200)
    }, [imgRef, onLoad])

    useEffect(() => {
        if (!completedCrop) return
        const image = imgRef.current

        if (!image || !completedCrop) {
            throw new Error('Crop canvas does not exist')
        }

        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height

        const offscreen = new OffscreenCanvas(
            185 * 2,
            185 * 2,
        )

        const ctx = offscreen.getContext('2d')

        if (!ctx) {
            throw new Error('No 2d context')
        }

        image.crossOrigin = 'anonymous';

        ctx.drawImage(
            image,
            completedCrop.x * scaleX,
            completedCrop.y * scaleY,
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
            0,
            0,
            offscreen.width,
            offscreen.height,
        )

        offscreen.convertToBlob({
            type: 'image/png',
        }).then((blob) => {
            setBlob(blob)
            setPreview(URL.createObjectURL(blob))
        })
    }, [completedCrop])

    const onSave = useCallback(async () => {
        const file = new File([blob], 'avatar.png', { type: 'image/png' })
        onAvatarSaveClick(file, blob)
    }, [blob, onAvatarSaveClick])

    const onComplete = useCallback((c) => {
        setCompletedCrop(c)
    }, [])

    return (
        <div className={styles.cropAvatar}>
            <ReactCrop
                crop={crop}
                onChange={c => setCrop(c)}
                onComplete={onComplete}
                circularCrop={true}
                aspect={1 / 1}
            >
                <img ref={imgRef} onLoad={onLoad} src={src} alt="avatar" />
            </ReactCrop>
            <img src={preview} style={{ display: 'none' }} alt="avatar preview" />
            <div className={styles.avatarChangeButtons}>
                <button disabled={loading} className={styles.button} onClick={onAvatarCancelClick}>Cancel</button>
                <button disabled={loading} className={styles.button} onClick={onSave}>Save</button>
            </div>
            <button disabled={loading} className={styles.button + ' ' + styles.upload} onClick={onAvatarUpload}>Upload new image</button>
        </div>
    )
}
