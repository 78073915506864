import { Textarea } from './Textarea'

export function Description({ value, onChange, error, generate }) {
    return (
        <Textarea
            name="description"
            text="Description will be used to generate messages. Describe your character (background, appearance, etc.)."
            placeholder="Description"
            onChange={onChange}
            maxLength={3000}
            value={value}
            error={error}
            generate={generate}
        />
    )
}