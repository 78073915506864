import { IoClose } from "react-icons/io5";
import styles from './Edit.module.css';

const ModerationGuide = ({ onClose }) => {
  return <div className={styles.moderationGuide}>
  <div className={styles.moderationGuideContent}>
    <div className={styles.moderationGuideHeader}>
      <IoClose size={50} onClick={onClose} />
    </div>

    <h2>Moderation guide</h2>

    <p>To ensure a safe and enjoyable experience for all users, character descriptions undergo automatic moderation to identify potentially harmful or inappropriate content. Below, you will find detailed explanations of the content categories that may trigger warnings. Understanding these categories can help you create appropriate descriptions and avoid unnecessary issues.
    </p>
    <p>If your character description triggers a warning, users will be notified of the nature of the flagged content before starting a conversation with the character. Characters with flagged content will still be accessible for public use, but creators are encouraged to revise flagged descriptions to remove warnings and improve the overall experience.
    </p>

    <h2>Content Classifications</h2>
    <p>Below are the types of content that may be flagged during moderation. Understanding these categories can help you create descriptions that comply with the platform's guidelines.</p>

    <div className="classification">
      <h3>1. Harassment</h3>
      <p><strong>Definition:</strong> Content that expresses, incites, or promotes harassing language towards any target.</p>
      <p><strong>Examples:</strong> Insulting language directed at specific individuals or groups.</p>
      <p><strong>Note:</strong> Does not include threats of violence (see below).</p>
    </div>

    <div className="classification">
      <h3>2. Harassment/Threatening</h3>
      <p><strong>Definition:</strong> Harassment content that includes threats of violence or serious harm toward a target.</p>
      <p><strong>Examples:</strong> Statements such as “I will hurt you” or similar threats.</p>
    </div>

    <div className="classification">
      <h3>3. Hate</h3>
      <p><strong>Definition:</strong> Content that expresses, incites, or promotes hate based on protected attributes such as:</p>
      <ul>
        <li>Race</li>
        <li>Gender</li>
        <li>Ethnicity</li>
        <li>Religion</li>
        <li>Nationality</li>
        <li>Sexual orientation</li>
        <li>Disability status</li>
        <li>Caste</li>
      </ul>
      <p><strong>Examples:</strong> Slurs, demeaning stereotypes, or calls to discriminate against groups.</p>
      <p><strong>Note:</strong> Content targeting non-protected groups is classified as harassment.</p>
    </div>

    <div className="classification">
      <h3>4. Hate/Threatening</h3>
      <p><strong>Definition:</strong> Hateful content that includes threats of violence or serious harm towards a targeted group based on protected attributes.</p>
      <p><strong>Examples:</strong> “People of [X] group should be hurt.”</p>
    </div>

    <div className="classification">
      <h3>5. Illicit</h3>
      <p><strong>Definition:</strong> Content providing advice or instructions for illegal acts.</p>
      <p><strong>Examples:</strong> “How to shoplift” or tips for committing fraud.</p>
    </div>

    <div className="classification">
      <h3>6. Illicit/Violent</h3>
      <p><strong>Definition:</strong> Illicit content that also includes references to violence or acquiring weapons.</p>
      <p><strong>Examples:</strong> Instructions on making weapons for illegal activities.</p>
    </div>

    <div className="classification">
      <h3>7. Self-Harm</h3>
      <p><strong>Definition:</strong> Content that promotes, encourages, or depicts self-harming acts.</p>
      <p><strong>Examples:</strong> Discussion of cutting, eating disorders, or suicidal ideation.</p>
      <p><strong>Subcategories:</strong></p>
      <ul>
        <li><strong>Self-Harm/Intent:</strong> Content where someone expresses intent to harm themselves.</li>
        <li><strong>Self-Harm/Instructions:</strong> Content encouraging or giving advice on self-harm.</li>
      </ul>
    </div>

    <div className="classification">
      <h3>8. Sexual</h3>
      <p><strong>Definition:</strong> Content intended to arouse sexual excitement or promote sexual services (excluding educational or wellness-related content).</p>
      <p><strong>Examples:</strong> Explicit descriptions of sexual activity.</p>
      <p><strong>Important:</strong> This does not include discussions of sex education or health.</p>
      <p><strong>Subcategory:</strong></p>
      <ul>
        <li><strong>Sexual/Minors:</strong> Sexual content involving individuals under 18. <strong>This is strictly prohibited.</strong></li>
      </ul>
    </div>

    <div className="classification">
      <h3>9. Violence</h3>
      <p><strong>Definition:</strong> Content depicting death, violence, or physical injury.</p>
      <p><strong>Examples:</strong> Descriptions of violent acts or injuries.</p>
      <p><strong>Subcategory:</strong></p>
      <ul>
        <li><strong>Violence/Graphic:</strong> Explicit and graphic descriptions of violence, physical harm, or death.</li>
      </ul>
    </div>

  </div>
</div>;
};

export default ModerationGuide;
