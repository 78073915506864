import { Textarea } from './Textarea'

export function SystemPrompt({ value, onChange, error, generate }) {
    return (
        <Textarea
            name="system_prompt"
            text="System prompt will be used to generate messages. Describe system prompt (tone, style, etc.)."
            placeholder="System prompt (optional)"
            onChange={onChange}
            maxLength={500}
            value={value}
            error={error}
            generate={generate}
        />
    )
}