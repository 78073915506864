import { useRef, useEffect, useCallback } from 'react'

import styles from './field.module.css';

export function Textarea({ name, value, onChange, placeholder, maxLength, text, error, generate, disabled }) {
    const ref = useRef(null)

    useEffect(() => {
        if (ref.current) {
            ref.current.style.height = "1px";
            ref.current.style.height = (25 + ref.current.scrollHeight) + "px";
        }
    }, [value])

    const textAreaAdjust = useCallback((e) => {
        e.target.style.height = "1px";
        e.target.style.height = (25 + e.target.scrollHeight) + "px";
    }, []);

    return (
        <>
            <div className={styles.field + ' ' + (error ? styles.error : '')}>
                <textarea disabled={disabled} placeholder={placeholder} ref={ref} value={value || ''} maxLength={maxLength} onChange={onChange} onKeyUp={textAreaAdjust} />
                {generate && <button disabled={disabled} onClick={() => generate(name, value, maxLength)} className={styles.generate}>Generate ⭐️</button>}
                <span className={styles.counter}>{value?.length || 0}/{maxLength}</span>
            </div>

            <span className={styles.text}>{text}</span>
            {error && <span className={styles.errorMessage}>{error}</span>}
        </>
    )
}