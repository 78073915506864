import { Textarea } from './Textarea'

export function Greeting({ value, onChange, error, generate }) {
    return (
        <Textarea
            name="first_message"
            text="Greeting is a short message that character will send to user first time"
            placeholder="Greeting"
            onChange={onChange}
            maxLength={500}
            value={value}
            error={error}
            generate={generate}
        />
    )
}