import React from 'react';

import styles from './Loader.module.css';

import logo from './assets/logo.png';

export const Loader = () => {
  return (
    <div className={styles.loader}>
      <img src={logo} alt="blabber.live" className={styles.logo} />
    </div>
  );
};
