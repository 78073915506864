import commonStyles from './../field.module.css';
import styles from './Gender.module.css';

export function Gender({ value, onChange, error }) {
    return (
        <div className={commonStyles.field + ' ' + styles.gender}>
            <div className={styles.tabs + ' ' + (error ? commonStyles.error : '')}>
                <div className={styles.tab + ' ' + (value === 'MALE' ? styles.active : '')} onClick={() => onChange({ target: { value: 'MALE' } })}>Male</div>
                <div className={styles.tab + ' ' + (value === 'FEMALE' ? styles.active : '')} onClick={() => onChange({ target: { value: 'FEMALE' } })}>Female</div>
                <div className={styles.tab + ' ' + (value === 'OTHER' ? styles.active : '')} onClick={() => onChange({ target: { value: 'OTHER' } })}>Non-binary</div>
            </div>
        </div>
    )
}
