import { Textarea } from './Textarea'

export function Scenario({ value, onChange, error, generate }) {
    return (
        <Textarea
            name="scenario"
            text="Scenario will be used to generate messages. Describe scenario of conversation (place, time, context of the conversation, etc.)."
            placeholder="Scenario (optional)"
            onChange={onChange}
            maxLength={1000}
            value={value}
            error={error}
            generate={generate}
        />
    )
}