import { Textarea } from './Textarea'

export function Bio({ value, onChange, error, generate }) {
    return (
        <Textarea
            name="bio"
            text="Bio is short character introduction for users"
            placeholder="Bio"
            onChange={onChange}
            maxLength={300}
            value={value}
            error={error}
            generate={generate}
        />
    )
}