import React from 'react'

import styles from './Background.module.css'

export const Background = () => {
    return (
        <div className={styles.background}>
            <div className={styles.top} />
            <div className={styles.middle} />
            <div className={styles.bottom} />
        </div>
    )
}